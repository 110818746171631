import React, { FC, useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./SimpleFilterItem.scss";
import { Box, Grid } from "@mui/material";
import { ImageIcon } from "components/shared/ImageIcon";
import { FilterItem } from "../../../types/targetV2.types";
import drag_and_drop from "../../../../../../src/assets/icons/icon-draganddrop.svg";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import * as XLSX from "xlsx";
type SimpleFilterItemProps = {
  index: number;
  filterItem: FilterItem;
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "70%",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    marginTop: "12px",
    padding: "8px",
    marginLeft: "14%",
    top: "7px",
  },
}));
const SimpleFilterItem: FC<SimpleFilterItemProps> = ({ index, filterItem }) => {
  const [infoIcon, setInfoIcon] = useState<any>("img-target-info");
  const [showInfoIcon, setShowInfoIcon] = useState<boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [showReadMore, setShowReadMore] = useState<boolean>(false);
  const [readMoreText, setReadMoreText] = useState<string>("");
  const [readMoreLink, setReadMoreLink] = useState<string>("");
  let classes = useStyles();
  const checkIsInfoBoxRequired = () => {
    fetch("excel/data.xlsx")
      .then((res: any) => res.arrayBuffer())
      .then((data: any) => {
        const fileData = XLSX.read(data, { type: "array" });
        const sheetName = fileData.SheetNames[0];
        const sheetData = fileData.Sheets[sheetName];
        const dataParsed = XLSX.utils.sheet_to_json(sheetData, { header: 1 });
        if (filterItem && filterItem.name) {
          const selectedInfo = dataParsed.filter((item: any) => {
            if (item && item[0] && item[0] === filterItem.name) {
              return true;
            } else {
              return false;
            }
          });
          const selectedArray =
            selectedInfo && selectedInfo[0] ? selectedInfo[0] : [];
          if (
            selectedArray &&
            Array.isArray(selectedArray) &&
            selectedArray.length > 0 &&
            selectedArray[1]
          ) {
            setShowInfoIcon(true);
            setInfoMsg(selectedArray[1]);
            if (selectedArray[2]) {
              setShowReadMore(true);
            }
            if (selectedArray[3]) {
              setReadMoreText(selectedArray[3]);
            }
            if (selectedArray[4]) {
              setReadMoreLink(selectedArray[4]);
            }
          } else {
            setShowInfoIcon(false);
            setInfoMsg("");
            setShowReadMore(false);
            setReadMoreText("");
            setReadMoreLink("");
          }
        }
      });
  };
  useEffect(() => {
    checkIsInfoBoxRequired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Draggable draggableId={filterItem.id.toString()} index={filterItem.id} key={filterItem.id}>
      {(provided, snapchot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="simple-filter-wrapper"
          boxShadow={snapchot.isDragging ? "0px 3px 6px #00000029" : ""}
        >
          <Box
            className="simple-filter-name"
            style={{
              color: snapchot.isDragging ? "#149CCC" : "",
            }}
          >
            {filterItem.name}
          </Box>
          <Box className="icons-container">
            {showInfoIcon && (
              <Tooltip
                title={
                  <Grid className="toolTip-container">
                    <span>{infoMsg}</span>
                    {showReadMore && (
                      <span>
                        <a href={readMoreLink} target="_blank" rel="noreferrer">
                          {readMoreText}
                        </a>
                      </span>
                    )}
                  </Grid>
                }
                placement="top"
                arrow
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                PopperProps={{ keepMounted: true }}
              >
                <Grid item>
                  <ImageIcon
                    classes={`${infoIcon} image-box`}
                    onMouseEnter={() => {
                      setInfoIcon("img-target-info-active");
                    }}
                    onMouseOut={() => {
                      setInfoIcon("img-target-info");
                    }}
                    style={{
                      paddingRight: snapchot.isDragging ? "10px" : "",
                    }}
                  ></ImageIcon>
                </Grid>
              </Tooltip>
            )}

            {snapchot.isDragging && (
              <Grid>
                <img
                  src={drag_and_drop}
                  alt="Drag and drop icon"
                  width="20px"
                />
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default SimpleFilterItem;
