import React, { FC, SetStateAction, useCallback, useEffect } from "react";
import { StrictModeDroppable } from "../StrictModeDroppable";
import "./DropDownFiltersContainer.scss";
import { Box, Grid } from "@mui/material";
import SimpleFilterItem from "../filterItems/simpleFilter/SimpleFilterItem";
import { FilterList } from "../../types/targetV2.types";
import drag_and_drop from "../../../../../src/assets/icons/icon-draganddrop.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "recoil/atoms";

type DropDownFiltersContainerProps = {
  filterList: FilterList;
  lastEl: boolean;
  setIsExpandCategoryFilter: React.Dispatch<
    SetStateAction<number | null | undefined>
  >;
  isExpandCategoryFilter: number | null | undefined;
};

const DropDownFiltersContainer: FC<DropDownFiltersContainerProps> = ({
  filterList,
  lastEl,
  setIsExpandCategoryFilter,
  isExpandCategoryFilter,
}) => {
  const { isSearching } = useTargetV2Context();
  const userDetails = useRecoilValue(userDetailsState);
  const updateExpandedCategoryFilters = useCallback(
    (id: number) => {
      if (isExpandCategoryFilter === id) {
        setIsExpandCategoryFilter(null);
      } else {
        setIsExpandCategoryFilter(id);
      }
    },
    [setIsExpandCategoryFilter, isExpandCategoryFilter]
  );

  useEffect(() => {
    if (isSearching) {
      setIsExpandCategoryFilter(filterList.id);
    } else {
      setIsExpandCategoryFilter(null);
    }
  }, [isSearching, filterList, setIsExpandCategoryFilter]);

  return (
    <StrictModeDroppable
      droppableId={`filterList-${filterList.id.toString()}`}
      key={filterList.id.toString()}
    >
      {(provided: any) => (
        <Grid
          display="flex"
          flexDirection="column"
          className="dropdown-wrapper"
          borderTop="1px dashed #707070"
          borderBottom={lastEl ? "1px dashed #707070" : ""}
        >
          <Box
            className="category-wrapper"
            onClick={() => updateExpandedCategoryFilters(filterList.id)}
            style={{ cursor: isSearching ? "auto" : "pointer" }}
          >
            <Box display="flex" gap="8px">
              <p className="category-filter-name">{filterList.name}</p>

              {(isExpandCategoryFilter === filterList.id || isSearching) && (
                <Box display="flex">
                  <Box display="flex" alignItems="center" className="dnd-title">
                    (&nbsp;
                    <img
                      src={drag_and_drop}
                      alt="Drag and drop icon"
                      width="15px"
                    />
                    Drag and drop &nbsp;)
                  </Box>
                </Box>
              )}
            </Box>
            <ArrowDropDownIcon
              style={{
                transform:
                  isExpandCategoryFilter === filterList.id || isSearching
                    ? "rotate(180deg)"
                    : "",
                cursor: isSearching ? "auto" : "pointer",
                color:
                  isExpandCategoryFilter === filterList.id || isSearching
                    ? "#149ccc"
                    : "inherit",
              }}
            />
          </Box>

          <div {...provided.droppableProps} ref={provided.innerRef}>
            {(isExpandCategoryFilter === filterList.id || isSearching) &&
              process.env.REACT_APP_ENV_NAME !== "prod" &&
              filterList.filterItemsChildren.map(
                (filterItem: any, index: any) => (
                  <SimpleFilterItem
                    key={filterItem.filterId}
                    index={index}
                    filterItem={filterItem}
                  />
                )
              )}
            {(isExpandCategoryFilter === filterList.id || isSearching) &&
              process.env.REACT_APP_ENV_NAME === "prod" &&
              filterList.filterItemsChildren.map(
                (filterItem: any, index: any) =>
                  filterItem.name !== "F2F, Suggestions" &&
                  filterItem.name !== "Survey" ? (
                    <SimpleFilterItem
                      key={filterItem.filterId}
                      index={index}
                      filterItem={filterItem}
                    />
                  ) : null
              )}
            {/* {(isExpandCategoryFilter === filterList.id || isSearching) &&            
              filterList.filterItemsChildren.map((filterItem, index) => (
                <>
                  {process.env.REACT_APP_ENV_NAME !== "prod" && (
                    <SimpleFilterItem
                      key={filterItem.filterId}
                      index={index}
                      filterItem={filterItem}
                    />
                  )}
                  {filterItem.name !== "F2F, Suggestions" &&
                    filterItem.name !== "Survey" &&
                    process.env.REACT_APP_ENV_NAME === "prod" && (
                      <SimpleFilterItem
                        key={filterItem.filterId}
                        index={index}
                        filterItem={filterItem}
                      />
                    )}
                </>
              ))} */}
            {provided.placeholder}
          </div>
        </Grid>
      )}
    </StrictModeDroppable>
  );
};

export default DropDownFiltersContainer;
