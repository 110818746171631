import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  notificationState,
  pushMsgCountState,
  selectedAppsInfoState,
} from "recoil/atoms";
import { Grid, Box, Typography } from "@mui/material";
import { dateFormatConvertor, parseJwt } from "helpers/sharedHelper";
import { useTranslation } from "react-i18next";
import { getSessionItem } from "core/utils/helper";
import { Tags } from "components/core/Tags";
import logo from "assets/icons/logo_precision_portal.svg";
import { Notification } from "components/Notification";
import { ImageIcon } from "components/shared/ImageIcon";
import { routes } from "core/constants";
import { useHistory } from "react-router-dom";
import { userCountryDetailsState, userDetailsState } from "recoil/atoms";
import { NotificationsList } from "pages/NotificationsList";

import "./NewHeader.scss";
import { getNotificationInfo } from "services/homeService";
import { CustomToggle } from "components/core/CustomToggle";
import { Menu } from "components/core/Menu";
export function NewHeader() {
  const { t } = useTranslation();
  let history = useHistory();
  const [userName, setUsername] = useState<string>("User");

  const [isActive, setActive] = useState<boolean>(false);
  const notificationConfig = useRecoilValue(notificationState);
  const selectedAppsInfo = useRecoilValue(selectedAppsInfoState);
  console.log("selectedAppsInfo Target", selectedAppsInfo.appUrl);

  const refViewLogoutPopup = useRef<any>();
  const [showNotificationsList, setShowNotificationsList] =
    useState<boolean>(false);

  let pushMsgCount = useRecoilValue(pushMsgCountState);

  const userCountryList = useRecoilValue(userCountryDetailsState);
  const [showCountries, setShowCountries] = useState(false);
  const userDetails = useRecoilValue(userDetailsState);
  let date = new Date();
  let dateNew = dateFormatConvertor(date, "dd-MM-yyyy");
  let email = userDetails.email;
  const [count, setCount] = useState(pushMsgCount);
  const logoutUrl =
    process.env.REACT_APP_KEYCLOAK_LOGOUT_URL +
    "?redirect_uri=" +
    process.env.REACT_APP_CLIENT_URL;
  const bayerSnowUrl =
    "https://bayergroup.sharepoint.com/:b:/s/AIPrecisionPortal/ESu5hHa9ZlZJmNpW5koluyIB6NDf0oNl87BpGVIp7-g4Zw";
  useEffect(() => {
    const accessToken = getSessionItem("client-token-access");
    const parsedobject = accessToken ? parseJwt(accessToken) : null;
    if (parsedobject && parsedobject.name) {
      setUsername(parsedobject.name);
      // let pattern = /\b\w/g;
      //let initials = parsedobject.name.match(pattern).join("").toUpperCase();
      // setShortName(initials);
    }
  }, []);

  const getNotification = () => {
    getNotificationInfo(dateNew, email)?.then((response: any) => {
      if (response && response.data && response.data.data) {
        if (response.data.data.length > count && count !== 0) {
          let unReadMsg = response.data.data.filter(
            (item: any) => item.readStatus === false
          );
          // setIsAlert(true);
          setCount(unReadMsg.length);
        }
      }
    });
  };

  let notificationTimer = useRef(getNotification);
  notificationTimer.current = getNotification;

  useEffect(() => {
    getNotification();
    const id = setInterval(() => notificationTimer.current(), 20000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the target pop is open and the clicked target is not within the pop up,
      // then close the target pop
      if (
        refViewLogoutPopup.current &&
        !refViewLogoutPopup.current.contains(e.target)
      ) {
        setActive(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);

  useEffect(() => {
    // notificationConfig.message ? setIsAlert(true) : setIsAlert(false);
    notificationConfig.duration > 0 &&
      setTimeout(() => {
        // setIsAlert(false);
      }, notificationConfig.duration);
  }, [notificationConfig]);

  const handleLogoutOption = () => {
    setActive(!isActive);
  };

  const handleNavigation = () => {
    setActive(!isActive);
    let path = routes.privacy;
    history.push(path);
  };

  const displayCountries = () => {
    setShowCountries(true);
  };

  const hideCoutries = () => {
    setShowCountries(false);
  };

  const getLogoutDropddown = () => {
    return (
      <>
        <Grid container className="tag-wrapper">
          <Grid item>
            <Grid className="country-heading" item>
              {t("multiple_countries_heading")}
            </Grid>
            <Grid item container>
              <Grid
                item
                className="tag-country"
                onMouseOver={displayCountries}
                onMouseOut={hideCoutries}
              >
                <Tags
                  tagClass="country-tag-class"
                  value={userCountryList[0]}
                  iconClass={`icon-${userCountryList[0]}`}
                >
                  <Box className="more-class">
                    +{userCountryList.length - 1}more
                  </Box>
                </Tags>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={`${
                  showCountries ? "show-countries" : "hide-countries"
                }`}
              >
                {userCountryList.slice(1).map((item: any) => {
                  return (
                    <Grid item xs={6} container paddingTop={"10px"} key={item}>
                      <Tags
                        tagClass="country-tag-class"
                        value={item}
                        iconClass={`icon-${item}`}
                      ></Tags>{" "}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item>
                <Tags
                  label={t("Therapeutical area")}
                  tagClass="therapeutic-area"
                ></Tags>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="logout-container">
          <div className="privacy-wrapper">
            <a
              href={bayerSnowUrl}
              className="logout-wrapper"
              target="_blank"
              rel="noreferrer"
            >
              <ImageIcon classes="icon-bayer-snow"></ImageIcon>
              <Box>
                <Typography align="center" className="privacy-label">
                  {t("bayer_snow")}
                </Typography>
              </Box>
            </a>
          </div>
        </Grid>
        <Grid container className="logout-container">
          <div onClick={handleNavigation} className="privacy-wrapper">
            <ImageIcon classes="icon-privacy"></ImageIcon>
            <Box>
              <Typography align="center" className="privacy-label">
                {t("privacy_statement")}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid container className="logout-container">
          <a href={logoutUrl} className="logout-wrapper">
            <ImageIcon classes="icon-logout"></ImageIcon>
            <Box>
              <Typography align="center" className="logout-label">
                {t("logout")}
              </Typography>
            </Box>
          </a>
        </Grid>
      </>
    );
  };

  const closeNotificationsList = () => {
    setShowNotificationsList(false);
  };
  return (
    <>
      {showNotificationsList && (
        <NotificationsList onclose={closeNotificationsList} />
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className="header-parent-new"
      >
        <Grid item alignItems="center" height="100%" xs={9} display={"flex"}>
          <Notification customToast={true} />
          <Grid item className="logo-wrapper">
            <Box className="logo-container">
              <img src={logo} alt="Logo" />
            </Box>
          </Grid>
          <Grid item xs={1.5}>
            <Box className="custom-toggle-container">
              <CustomToggle />
            </Box>
          </Grid>
          {/* <Grid item xs={8}>
            {selectedAppsInfo.appUrl && (
              <Box height={"100%"}>
                {
                  <Menu
                    targetAppPage={selectedAppsInfo.appUrl ? true : false}
                  />
                }
              </Box>
            )}
          </Grid> */}
        </Grid>

        <Grid
          item
          xs={3}
          className="wrapper-block"
          justifyContent={"flex-end"}
          height="100%"
          alignItems="center"
        >
          <Grid item className="user-name-block">
            <Grid item className="hour-glass-wrapper">
              <Box
                className={`hour-glass ${
                  getSessionItem("async-call-set") === "sent" ? "msg-sent" : ""
                }`}
              >
                <ImageIcon
                  classes={`${
                    getSessionItem("async-call-set") === "sent"
                      ? "icon-hour-glass-red"
                      : ""
                    // : "icon-hour-glass-green"
                  }`}
                  // classes="icon-hour-glass-red"
                ></ImageIcon>
              </Box>
            </Grid>

            <Grid
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              onClick={handleLogoutOption}
              className="User-name-wrapper"
            >
              <ImageIcon className="name-initials"></ImageIcon>

              <Typography className="user-name"> {userName} </Typography>
              <Box className="arrow-down"></Box>
            </Grid>
            <Grid
              item
              className={isActive ? "show-logout-block" : "hide-logout-block"}
              ref={refViewLogoutPopup}
            >
              {getLogoutDropddown()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
