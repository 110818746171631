import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Filter from "./filter/Filter";
import { Popup } from "components/shared/Popup";
import { CustomFileDropzone } from "../../../../components/shared/FileDropzone/CustomFileDropzone";
import CustomPreview from "../customPreview/customPreview";
import { ImageIcon } from "components/shared/ImageIcon";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { userOperationState, CRMIDPresentState } from "recoil/atoms";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "160px",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    padding: "8px",
    top: "7px",
  },
}));

const CustomFiltersContainer = (props: any) => {
  const { t } = useTranslation();
  let classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const [openPreviewPopup, setOpenPreviewPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [userOperation, setUserOperation] = useState("");
  const [targetCount, setTargetCount] = useState(0);
  const setUserOperationState = useSetRecoilState(userOperationState);
  const idPresent = useRecoilValue(CRMIDPresentState);

  useEffect(() => {
    if (props.targetListCsvProcessingUrl) {
      const segements = props.targetListCsvProcessingUrl
        .split("/")
        .filter((segment: any) => segment);
      const lastSegment = segements[segements.length - 1];
      setCustomUrl(lastSegment);
    }
  }, [props.targetListCsvProcessingUrl]);

  const openIframePopup = () => {
    customUrl ? setOpenPopup(true) : setOpenPopup(false);
  };
  const closeIframePopup = () => {
    setOpenPopup(false);
  };
  const openAddHcpPopup = () => {
    setUserOperationState("Add");
    setUserOperation("Add");
    setOpenUploadPopup(true);
  };
  const openRemoveHcpPopup = () => {
    setUserOperationState("Remove");
    setUserOperation("Remove");
    setOpenUploadPopup(true);
  };
  const openPreviewUserPopup = () => {
    setOpenPreviewPopup(true);
  };
  const closePreviewUserPopup = () => {
    setOpenPreviewPopup(false);
  };
  const closeUploadPopup = () => {
    setOpenUploadPopup(false);
  };

  const openSuccess = (count: any) => {
    setTargetCount(count);
    setOpenSuccessPopup(true);
  };
  const closeSuccess = () => {
    setOpenSuccessPopup(false);
  };
  const ValidateFile = () => {
    setOpenUploadPopup(false);
    openPreviewUserPopup();
  };
  const openRetryPopup = () => {
    closePreviewUserPopup();
    setOpenUploadPopup(true);
  };

  return (
    <>
      <Grid onClick={openIframePopup} style={{ color: "red" }}>
        <Filter
          name="Add your customised filter"
          color={props.targetListCsvProcessingUrl ? "#2D9DE2" : " #969696"}
          bgColor={props.targetListCsvProcessingUrl ? "#F5FFFF" : "#dde5ea"}
          icon={
            <AddIcon
              style={
                props.targetListCsvProcessingUrl
                  ? { color: "#2D9DE2" }
                  : { color: "#969696" }
              }
            />
          }
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>
      <Grid
        onClick={props.targetListCsvProcessingUrl ? openAddHcpPopup : undefined}
      >
        <Filter
          name="Add HCPs to the target list"
          color={props.targetListCsvProcessingUrl ? "#000000" : "#969696"}
          bgColor={props.targetListCsvProcessingUrl ? "#E1F8F7" : "#dde5ea"}
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>
      {props.targetListData.hasOwnProperty("countHCPS") &&
      !props.targetListData.countHCPS ? (
        <Tooltip
          title="The target list is empty. No HCPs can be removed!"
          placement="top"
          arrow
          classes={{
            arrow: classes.arrow,
            tooltip: classes.tooltip,
          }}
          PopperProps={{ keepMounted: true }}
        >
          <Grid>
            <Filter
              name="Remove HCPs to the target list"
              color="#969696"
              bgColor="#dde5ea"
              isPointer={false}
            />
          </Grid>
        </Tooltip>
      ) : (
        <Grid
          onClick={
            props.targetListCsvProcessingUrl ? openRemoveHcpPopup : undefined
          }
        >
          <Filter
            name="Remove HCPs to the target list"
            color={
              props.targetListCsvProcessingUrl && props.targetListData.countHCPS
                ? "#000000"
                : "#969696"
            }
            bgColor={
              props.targetListCsvProcessingUrl && props.targetListData.countHCPS
                ? "#F8ECE1"
                : "#dde5ea"
            }
            isPointer={
              props.targetListCsvProcessingUrl && props.targetListData.countHCPS
                ? true
                : false
            }
          />
        </Grid>
      )}
      <Popup
        open={openPopup}
        headerIcon="icon-popup-info"
        class="send-sfmc-popup-wrapper"
        handleClose={closeIframePopup}
      >
        <Grid item>
          <iframe
            title={"Csv url"}
            height="1058"
            // src={`https://dev-admin.precisionai-portal.bayer.com:8093/?method=post&bucketname=pai-backend-api-csv-development&filename=targets_details/${customUrl}`}
            src={`${process.env.REACT_APP_CUSTOM_FILTER_URL}?method=post&bucketname=${process.env.REACT_APP_AWS_BUCKET}&filename=targets_details/${customUrl}`}
            width="100%"
            style={{ border: "none", overflow: "hidden" }}
          />
        </Grid>
      </Popup>
      <Popup
        open={openUploadPopup}
        title={
          userOperation === "Add"
            ? t("custom_file_upload_add_title")
            : t("custom_file_upload_remove_title")
        }
        headerIcon="icon-file-upload"
        class="user-file-popup"
        handleClose={closeUploadPopup}
      >
        <Grid item>
          <CustomFileDropzone
            onValidateFile={ValidateFile}
          ></CustomFileDropzone>
        </Grid>
      </Popup>
      <Popup
        open={openPreviewPopup}
        title={
          userOperation === "Add"
            ? t("validate_added_HCP_title")
            : t("validate_removed_HCP_title")
        }
        headerIcon="icon-file-upload"
        parentClass="preview-table-container"
        maxWidth={idPresent ? "" : "no-crm-id"}
        handleClose={closePreviewUserPopup}
      >
        <CustomPreview
          onRetry={openRetryPopup}
          onSuccess={openSuccess}
          onClose={closePreviewUserPopup}
        />
      </Popup>
      <Popup
        open={openSuccessPopup}
        handleClose={closeSuccess}
        title={userOperation === "Add" ? t("hcps_added") : t("hcps_removed")}
        maxWidth="md"
        class="save-user-upload-popup"
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className="save-success-popup"
          direction="column"
        >
          <>
            <Grid item className="target-success-icon">
              <ImageIcon classes="icon-target-success"></ImageIcon>
            </Grid>
            <Grid className="success_msg">
              {targetCount} {targetCount > 1 ? "HCPs are" : "HCP is"}{" "}
              successfully {userOperation === "Add" ? "added" : "removed"}
            </Grid>
          </>
        </Grid>
      </Popup>
    </>
  );
};

export default CustomFiltersContainer;
